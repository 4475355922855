import { requestGet, requestPost } from "@/axios/request"

const platform = '/live-stream/backend'

/**
 * 登陆-获取验证码
 */
 export const getLoginCode = (data = {}, config = {}) => {
    return requestGet(`${platform}/logincode`, data, { notNeedToken: true, responseType: 'blob', isHeader: true, ...config })
}

/**
 * 登陆-用户登陆
 */
 export const userLogin = (data = {}, config = {}) => {
    return requestPost(`${platform}/login`, data, { notNeedToken: true, isNQS: true, ...config})
}


/**
 * 设备绑定管理
 */
 export const getDeviceBindList = (data = {}, config = {}) => {
    return requestGet(`${platform}/userDeviceBindRel/page`, data, { isNQS: true, ...config})
}

/**
 * 设备绑定管理
 */
 export const updateDeviceStatus = (data = {}, config = {}) => {
    return requestPost(`${platform}/userDeviceBindRel/updateStatus`, data, {jsonType: true, ...config})
}

/**
 * 超管用户列表
 */
 export const getAdminList = (data = {}, config = {}) => {
    return requestGet(`${platform}/admin/page`, data, { isNQS: true, ...config})
}
/**
 * 新增超管用户
 */
 export const addAdminUser = (data = {}, config = {}) => {
    return requestPost(`${platform}/admin/add`, data, { jsonType: true, ...config})
}
/**
 * 禁用超管用户
 */
 export const disableAdmin = (data = {}, config = {}) => {
    return requestPost(`${platform}/admin/disableAdmin`, data, { jsonType: true, ...config})
}
/**
 * 启用超管用户
 */
 export const enableAdmin = (data = {}, config = {}) => {
    return requestPost(`${platform}/admin/enableAdmin`, data, { jsonType: true, ...config})
}

/**
 * 启用超管用户
 */
 export const deleteAdmin = (data = {}, config = {}) => {
    return requestPost(`${platform}/admin/delete`, data, { jsonType: true, ...config})
}

/**
 * 流量管理-列表
 */
 export const getFlowList = (data = {}, config = {}) => {
    return requestGet(`${platform}/live-equip/page`, data, { isNQS: true, ...config})
}
/**
 * 流量管理-禁用SIM卡
 */
 export const disableSimCard = (data = {}, config = {}) => {
    return requestPost(`${platform}/live-equip/sim/disable`, data, { jsonType: true, ...config})
}
/**
 * 流量管理-启用SIM卡
 */
 export const enableSimCard = (data = {}, config = {}) => {
    return requestPost(`${platform}/live-equip/sim/enable`, data, { jsonType: true, ...config})
}
/**
 * 流量管理-获取sim状态
 */
export const getSimStatus = (data = {}, config = {}) => {
    return requestGet(`${platform}/live-equip/sim/getSimStatus`, data, { jsonType: true, ...config})
}


/**
 * 视频盒子管理-列表
 */
export const getLiveVBoxList = (data = {}, config = {}) => {
    return requestGet(`${platform}/live-vbox/page`, data, { isNQS: true, ...config})
}

/**
 * 视频盒子管理-添加视频盒子
 */
export const addVBox = (data = {}, config = {}) => {
    return requestPost(`${platform}/live-vbox/addBox`, data, { jsonType: true, ...config})
}

/**
 * 视频盒子管理-修改视频盒子
 */
export const updateVBox = (data = {}, config = {}) => {
    return requestPost(`${platform}/live-vbox/updateBox`, data, { jsonType: true, ...config})
}

/**
 * 视频盒子管理-删除视频盒子
 */
export const deleteVBox = (data = {}, config = {}) => {
    return requestPost(`${platform}/live-vbox/delBox`, data, { jsonType: true, ...config})
}

export const downloadModule = (data = {}, config = {}) => {
    return requestGet(`${platform}/live-vbox/downloadTemplate`, data, { responseType: 'blob', ...config})
}

/**
 * 视频盒子管理-视频盒子导入检查
 */
 export const importBoxCheck = (data = {}, config = {}) => {
    return requestPost(`${platform}/live-vbox/importBoxCheck`, data, { isNQS: true,headers: {'Content-Type':'multipart/form-data; boundary=----WebKitFormBoundaryV0TrRuSb98VesHCw'}, ...config})
}
 export const importBox = (data = {}, config = {}) => {
    return requestPost(`${platform}/live-vbox/importBox`, data, { isNQS: true,headers: {'Content-Type':'multipart/form-data; boundary=----WebKitFormBoundaryV0TrRuSb98VesHCw'}, ...config})
}
